import * as React from 'react'

import { Container } from '../shared/Grid'
import { Box } from '../shared/Helpers'
import RenderHtml from '../shared/RenderHtml'
import { Description, MainTitle } from '../shared/Typography/'
import { ContentStyle } from './parts'
import { IMDContent } from './types'

export default function renderMDContent({
  data: { title, description },
  html,
}: IMDContent) {
  return (
    <Container>
      <Box width={['100%', '50%']}>
        <MainTitle mb="1rem" mt="4rem">
          {title}
        </MainTitle>
        {description && (
          <Description color="greyMedium">{description}</Description>
        )}
      </Box>

      <Box m={['2em 0 4em 0', '4em 0 8em 0']} px={[null, '0', '6em']}>
        <ContentStyle>
          <RenderHtml html={html} />
        </ContentStyle>
      </Box>
    </Container>
  )
}
