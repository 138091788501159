import { captionStyle, descriptionStyle } from '../shared/Typography/'
import styled from '../styled'

export const ContentStyle = styled.div`
  * {
    margin-bottom: 1em;
  }

  h2 {
    ${captionStyle}

    & + *:not(p):not(ul):not(li) {
      margin-top: 3em;
    }
  }

  h3 {
    ${descriptionStyle}

    & + *:not(p):not(ul):not(li) {
      margin-top: 3em;
    }
  }

  p {
    ${descriptionStyle}
    color: ${({ theme: { colors } }) => colors.grayscale.medium};

    & + *:not(p):not(ul):not(li) {
      margin-top: 3em;
    }
  }

  ul {
    & + *:not(p):not(ul):not(li) {
      margin-top: 3em;
    }
  }

  li {
    ${descriptionStyle}
    color: ${({ theme: { colors } }) => colors.grayscale.medium};
    margin-left: 2em;
    list-style: disc;

    & + *:not(p):not(ul):not(li) {
      margin-top: 3em;
    }
  }
`
