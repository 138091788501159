import { graphql } from 'gatsby'
import * as React from 'react'

import MdContent from '../components/MdContent'
import SEO from '../components/SEO'
import { IProp } from '../interfaces/contentpage'
import Layout from '../layouts'
import mergeEdges from '../utils/mergeEdges'

export const query = graphql`
  query PrivacyPolicy {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "privacy-policy" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
          }
          html
        }
      }
    }
  }
`

const privacyPolicyPage: React.FC<IProp> = ({
  data: { allMarkdownRemark },
}) => {
  const data = mergeEdges(allMarkdownRemark)

  if (data && data.frontmatter && data.html) {
    return (
      <Layout>
        <SEO />
        <MdContent data={data.frontmatter} html={data.html} />
      </Layout>
    )
  }

  return null
}

export default privacyPolicyPage
